<template>
    <div>
        BoardFilterCommuOnlyTabLayout
    </div>
</template>
<!--dd-->
<script>
    import EventBus from "@/utils/event-bus";

    export default {
        name: "BoardFilterCommuOnlyTabLayout",
        props: ['filterData', 'setData'],
        components: {
        },
        data() {
            return {
                selectCate: '',
            }
        },
        computed: {
            categorys() {
                return this.filterData.hasOwnProperty('categorys') ? this.filterData.categorys : [];
            },
            categorysLength() {
                this.$forceUpdate()
                return this.filterData.hasOwnProperty('categorys') ? this.filterData.categorys.length : 0;
            },
        },
        mounted() {

        },
        methods: {
            getCategorys() {
                if (this.filterData.hasOwnProperty('categorys')) {
                    return this.filterData.categorys
                }
                return []
            },
            selectCategoryFunc(idx) {
                this.selectCate = idx;
                let filterData = this.filterData;
                filterData.filter.fc_idx = this.selectCate;
                this.setData('filterData', filterData);
                EventBus.$emit('getBoardList', true)
            },
            hasActive(idx) {
                if (this.selectCate === idx) {
                    return 'active';
                }
                return '';
            },

        },
        watch: {},

    }
</script>

<style scoped>

</style>